<template>
  <section>
    <landingCompon :class="`detect_deviece_${deviceType} state_${deviceType}`" />
  </section>
</template>

<script>
import landingCompon from '@/pages/combine/landing.vue';
import '@/css/main.scss';

export default {
  name: 'LandingMain',
  components: {
    landingCompon,
  },
  computed: {
    deviceType () {
      return this.$store.state.deviceType;
    },
  },
};
</script>
