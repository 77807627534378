<template>
  <div id="landing">
    <div class="back" @click="goback()">
      <img src="@/assets/news/back.png" alt="">
    </div>
    <section v-if="hasNews" id="el" class="content">
      <img v-lazy="poster" class="figure_poster" :alt="poster">
      <h1>
        {{ title }}
      </h1>

      <div class="news-info-row">
        <div>
          {{ channel }}
        </div>
        <div class="round" />
        <div>
          {{ publishedTime }}
        </div>
      </div>

      <div class="slice-line" />
      <div class="adsItem">
        <ins class="adsbygoogle" style="display: block" :data-ad-client="adsense.client"
          :data-ad-slot="adsense.slot3" data-ad-format="auto" data-full-width-responsive="true" />
      </div>
      <section v-for="(context, i) in content" :key="i" class="content-row">
        <h2 v-if="context.subTitle" v-html="context.subTitle" />
        <img v-show="i > 0" v-if="context.img" v-lazy="context.img" class="figure"
          :alt="context.subTitle">
        <div v-html="context.content" />
      </section>
    </section>

    <section v-if="!hasNews && requirDataAlready" class="no-data-row">
      <img src="@/assets/news/nodata.png" alt="">
      <div class="no-data">
        Uh-oh. What you were looking for got away from you
      </div>
    </section>

    <div v-if="!requirDataAlready" class="loading">
      <img src="@/assets/news/loading.png" alt="">
    </div>
  </div>
</template>

<script>
import '@/css/news/landing.scss';
export default {
  name: 'LandingCombine',
  data () {
    return {
      requirDataAlready: false,
      channel: '',
      poster: '',
      sourceLink: '',
      title: '',
      newDesc: '',
      publishedTime: '',
      content: '',
      hasNews: false,

      newsId: '',
      showToast: false,
      toastTxt: 'The video link has been copied to the clipboard, you can share with your friends now',
      newsInfo: '',
      adsense: null,
      activatedStep: false,  // 用于检查是否进入了activated步
    };
  },
  async mounted () {
    console.log('mounted-------------------');
    this.newsId = this.$route.query.id;
    this.adsense = this.$store.state.adsenseConfig;

    if (this.newsId) {
      try {
        await this.getNews();
        this.loadAdSenseScript();
      } catch (error) {
        console.error('An error occurred in getList:', error);
        // 可以在这里添加错误处理逻辑
      }
    }
    this.$eventrackFb('show_details_h5', 'expose', {
      website: this.website,
      id: this.newsId,
      pve_cur: '/h5page/news/details/',
    });
  },
  beforeDestroy () {
    console.log('beforeDestroy-------------------');
    this.removeAdScript();
  },
  methods: {
    initializeComponent () {
      if (!window.adsbygoogle || window.adsbygoogle.length === 0 || !window.adsbygoogle.loaded) {
        console.log("reloading Adsense script.");
        this.loadAdSenseScript();  // 重新加载广告脚本
      }
    },
    removeAdScript () {
      // 移除广告实例
      const ads = document.querySelectorAll('.adsbygoogle');
      ads.forEach(ad => {
        ad.remove();  // 如果是简单的广告，直接移除可能就足够了
      });

      // 重置 window.adsbygoogle 数组
      window.adsbygoogle = [];

      // 移除广告脚本标签
      const adScript = document.querySelector('script[src="' + this.adsense?.scriptUrl + '"]');
      if (adScript) {
        adScript.remove();
      }

    },
    // loadAdSenseScript () {
    //   // 先检查广告是否已经加载
    //   if (window.adsbygoogle) {
    //     this.loadAdWithDelay();
    //   } else {
    //     this.loadScriptConditionally();
    //   }
    // },
    loadAdSenseScript () {
      // 其他需要在初次加载时执行的操作
      // 先检查广告是否已经加载
      if (window.adsbygoogle && window.adsbygoogle.loaded) { // 检查广告是否已经加载
        console.log("Adsense script already loaded.");
        return; // 如果已加载，直接返回
      }
      this.loadScriptConditionally();
    },
    loadAdWithDelay () {
      setTimeout(this.displayAd, 1500); // 延迟100毫秒初始化广告
    },
    loadScriptConditionally () {
      // 判断广告脚本URL是否存在
      if (!this.adsense?.scriptUrl) {
        console.log('广告脚本的URL不存在,终止加载广告外链');
        return;
      }
      // 检查该脚本是否已经被添加
      const existingScript = document.querySelector(`script[src="${this.adsense.scriptUrl}"]`);
      if (existingScript) {
        console.log('脚本已存在，无需重新添加');
        return;
      }

      const script = document.createElement('script');
      script.src = this.adsense?.scriptUrl;
      script.crossOrigin = 'anonymous';
      script.async = true;

      const header = document.getElementsByTagName('head')[0];
      header.appendChild(script);

      script.onload = this.loadAdWithDelay.bind(this); // 使用 bind 确保 this 指向正确
    },
    displayAd () {
      // if (this.activatedStep) return //进入activated后会执行加载那些,而实例其实已经创建过了 不必再插入广告

      (window.adsbygoogle = window.adsbygoogle || []).push({});
    },
    // removehref () {
    //   let hrefss = document.getElementsByClassName('content')[0];
    //   Array.prototype.slice.call(hrefss.getElementsByTagName('a')).forEach(function (item) {
    //     item.href = 'javascript:void(0);';
    //   });
    // },
    async getNews (id) {
      this.hasNews = false;
      this.toastTxt = 'Loading...';
      this.showToast = true;

      try {
        const res = await this.$global.server.getNews({
          id: id ? id : this.newsId,
        });

        this.requirDataAlready = true;

        if (res.code != 200) {
          console.log('获取失败');
          this.toastTxt = "Opps, there aren't more news to show";
          this.showToast = true;
          return;
        }

        this.hasNews = true;
        this.title = res.data.title;
        this.channel = res.data.channel;
        this.publishedTime = res.data.published;
        this.content = res.data.articleDetails;
        this.showToast = false;
        this.poster = res.data.articleDetails?.[0].img;
        console.log(this.poster);

        // setTimeout(() => {
        //   this.removehref();
        // }, 500);
      } catch (err) {
        console.error('An error occurred while getting news:', err);
        this.toastTxt = "An error occurred while loading news.";
        this.showToast = true;
      }
    },
    goback () {
      this.$router.go(-1);
    },
  },
};
</script>
